import React from 'react'
import styled from 'styled-components'
import Image from 'next/legacy/image'
import { Column, Row } from 'components/common/layout'
import { ScoutRequestAiAssessment } from 'types/common'
import { BaseText, SemiBoldText } from 'components/common/text'
import {
  AssessmentCard,
  AssessmentScoreText,
  AssessmentScoreWrapper,
} from 'components/dashboard/scouts/profile/styles'

const ensureDecimal = (num: number) => {
  const result = num.toFixed(1) // 소수점 1자리만 남김
  return result
}

const evaluateScore = (score: number, maxScore: number) => {
  const percentage = (score / maxScore) * 100
  if (percentage >= 85) {
    return 'good'
  } else if (percentage < 35) {
    return 'not'
  } else {
    return 'potential'
  }
}

export const AiAssessment: React.FC<{
  data: ScoutRequestAiAssessment
  isNarrow?: boolean
}> = ({ data, isNarrow }) => {
  return (
    <AssessmentCard>
      <Column style={{ gap: 8, alignItems: 'flex-start' }}>
        <AssessmentScoreWrapper>
          <Row style={{ flex: 'none' }}>
            <Image
              src={`/${
                data.totalScore >= 8
                  ? 'good'
                  : data.totalScore < 3
                  ? 'not'
                  : 'potential'
              }-match-square.svg`}
              alt='match-square'
              width={24}
              height={24}
            />
          </Row>
          <AssessmentScoreText>
            {ensureDecimal(data.totalScore)}
          </AssessmentScoreText>
        </AssessmentScoreWrapper>
        <RegularSubText>{data.summary}</RegularSubText>
      </Column>
      {data.sections.map((x) => (
        <Item key={x.description}>
          <Row style={{ flex: 'none' }}>
            <Image
              src={`/${evaluateScore(x.score, x.maxScore)}-match-square.svg`}
              alt='match-square'
              width={24}
              height={24}
            />
          </Row>
          <Column style={{ gap: 8 }}>
            <SemiBoldText>{x.description}</SemiBoldText>
            {!isNarrow && <RegularSubText>{x.reason}</RegularSubText>}
          </Column>
        </Item>
      ))}
    </AssessmentCard>
  )
}

const Item = styled(AssessmentScoreWrapper)`
  align-items: flex-start;
`

const RegularSubText = styled(BaseText)`
  font-size: ${(p) => p.theme.fontWeight.regular};
  line-height: 22px;
`
