import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import {
  DashboardScoutRequestOneQueryParams,
  ScoutRequestBase,
} from 'types/common'
import {
  BaseText,
  MediumText,
  SemiBoldText,
  SmallText,
} from 'components/common/text'
import { Card } from 'components/common/card'
import { theme } from 'infra/theme'
import { LineLimitedText } from 'components/scouts/profile-detail/desc-more-view'
import { CareerDetail } from 'components/scouts/profile/career-detail'
import { UserBaseInfo } from 'components/scouts/profile/common/user-base-info'
import { Tag, TagContainer, TagType } from 'components/common/tag'
import { Attachments } from 'components/scouts/profile-detail/attachments'
import { DevEducation } from 'components/scouts/profile-detail/dev-education'
import { ManagerComment } from 'components/dashboard/scouts/profile/manager-comment'
import { getConditions, getFlattenedData } from 'utils/formatters'
import { OtherActivityDetail } from 'components/scouts/profile/other-activity-detail'
import Image from 'next/legacy/image'
import { convertNumberToHyphenPhone } from 'utils/displays'
import { useRouter } from 'next/router'
import { analytics } from 'infra/analytics'
import { MyLink } from 'components/common/link'
import { LanguageSkillDetail } from 'components/scouts/profile/language-skill-detail'
import { ProfileDetailSectionSubText } from 'components/scouts/search/styles'
import { ConditionTags } from 'components/scouts/search/condition-tags'
import { postRequestDashboardScoutProposalRequestResume } from 'apis/request'
import { InformationBox } from 'components/misc/information-box'
import { useBaseInfo } from 'apis/hooks'
import { useIsLoggedInAdmin } from 'utils/hooks'
import { AiAssessment } from 'components/dashboard/scouts/profile/ai-assessment'

const SectionPlaceholder: React.FC<{ placeholder: string; button: string }> = ({
  placeholder,
  button,
}) => {
  return (
    <MyLink
      href='/scouts/my-profile'
      onClick={() =>
        analytics.track('click_sp_go_to_fill_profile_from_preview')
      }
    >
      <PlaceholderCard>
        <PlaceholderText>❗️ {placeholder}</PlaceholderText>
        <TextButton>{button}</TextButton>
      </PlaceholderCard>
    </MyLink>
  )
}

const ProfileFillButton: React.FCC = ({ children }) => {
  return (
    <TextButtonWrapper>
      <MyLink
        href='/scouts/my-profile'
        onClick={() =>
          analytics.track('click_sp_go_to_fill_profile_from_preview')
        }
      >
        <TextButton>{children}</TextButton>
      </MyLink>
    </TextButtonWrapper>
  )
}

export const ProfileDetail: React.FC<{
  data: ScoutRequestBase & {
    coverLetter?: string | null
    email?: string
    phone?: string
    isResumeAllowed?: boolean
  }
  params?: DashboardScoutRequestOneQueryParams
  curationId?: number
  isChatBlocked?: boolean
  isNarrow?: boolean
  shouldInduceToFill?: boolean // 인재 사이드에서 미리보기할 때 미완된 정보를 표시하고 작성 유도하기 위함
}> = ({
  data,
  params,
  curationId,
  isNarrow,
  isChatBlocked,
  shouldInduceToFill,
}) => {
  const router = useRouter()
  const isAdmin = useIsLoggedInAdmin()
  const { data: baseInfo } = useBaseInfo()
  const proposalId = Number(router.query.proposalId) || null
  const isChatRooms = router.pathname.includes('chat-rooms')
  const isScoutChatRoom = isChatRooms && !!proposalId
  const isApplicant = router.pathname.includes('applicants')
  const hasAttachment = data.files.length > 0 || data.links.length > 0

  const flattenedLocations = useMemo(
    () =>
      getFlattenedData(
        baseInfo?.locations.filter((item) => !item.isRemote) || [],
        data.locations || [],
      ),
    [baseInfo?.locations, data.locations],
  )

  const requiredConditions = getConditions({
    profile: {
      ...data,
      locations: flattenedLocations,
    },
    isRequired: true,
  })
  const normalConditions = getConditions({
    profile: {
      ...data,
      locations: flattenedLocations,
    },
    isRequired: false,
  })

  return (
    <Container>
      {/* 어드민만 프로필이 보임 */}
      {isAdmin && !data.isPublished && !data.isDeleted && (
        <UnpublishedBanner>
          <span>주의 ! 비공개된 프로필입니다</span>
          <span>주의 ! 비공개된 프로필입니다</span>
          <span>주의 ! 비공개된 프로필입니다</span>
        </UnpublishedBanner>
      )}
      <UserInfoCardWrapper>
        <UserBaseInfo data={data} params={params} isNarrow={isNarrow} />
        {data.email && data.phone && (
          <Section>
            <SectionTitle>연락처</SectionTitle>
            <Row style={{ gap: 8 }}>
              <Image src='/phone-call.svg' width={18} height={18} alt='phone' />
              <ContactText href={`tel:${data.phone}`}>
                {convertNumberToHyphenPhone(data.phone)}
              </ContactText>
            </Row>
            <Row style={{ gap: 8 }}>
              <Image src='/mail.svg' width={18} height={18} alt='mail' />
              <ContactText href={`mailto:${data.email}`}>
                {data.email}
              </ContactText>
            </Row>
          </Section>
        )}
        {data.coverLetter ||
        !!data.summary?.length ||
        !!data.managerComment?.manager ||
        !!data.aiAssessment ? (
          <Column style={{ gap: 16 }}>
            {!!data.aiAssessment && <AiAssessment data={data.aiAssessment} />}
            {data.coverLetter && (
              <CoverLetterBox>
                <BoxHeader>지원 메시지</BoxHeader>
                <BaseText>{data.coverLetter}</BaseText>
              </CoverLetterBox>
            )}
            {data.managerComment && data.managerComment.manager && (
              <ManagerComment
                managerComment={data.managerComment}
                isNarrow={isNarrow}
              />
            )}
            {!!data.summary?.length && (
              <SummaryCard>
                {!isNarrow && (
                  <BaseText style={{ color: theme.color.gray2 }}>
                    AI 프로필 요약
                  </BaseText>
                )}
                {isNarrow ? (
                  <MediumText>
                    {data.summary.map((item) => item.title).join(' / ')}
                  </MediumText>
                ) : (
                  <SummaryList>
                    {data.summary.map((item, index) => (
                      <div key={index}>
                        <li dangerouslySetInnerHTML={{ __html: item.title }} />
                        <p dangerouslySetInnerHTML={{ __html: item.content }} />
                      </div>
                    ))}
                  </SummaryList>
                )}
              </SummaryCard>
            )}
          </Column>
        ) : (
          ''
        )}
      </UserInfoCardWrapper>
      {shouldInduceToFill &&
        !data.introduction &&
        !data.personalities.length && (
          <SectionPlaceholder
            placeholder='"자기소개" 가 없어요'
            button='작성하러 가기 (+ 최대 3점)'
          />
        )}
      {(data.introduction || data.personalities.length > 0) && (
        <Section>
          <SectionTitle>자기소개</SectionTitle>
          {data.personalities.length > 0 && (
            <TagContainer>
              {data.personalities.map((keyword) => (
                <Tag
                  key={keyword.id}
                  dangerouslySetInnerHTML={{ __html: keyword.name }}
                />
              ))}
            </TagContainer>
          )}
          <TextWrapper>
            <LineLimitedText text={data.introduction || ''} />
          </TextWrapper>
          {shouldInduceToFill &&
            (!data.introduction || data.introduction.length < 20) && (
              <ProfileFillButton>
                {`"자기소개" 보충하러 가기 (+ 2점)`}
              </ProfileFillButton>
            )}
        </Section>
      )}
      {shouldInduceToFill &&
        !data.expectation.description &&
        !data.expectation.keywords.length && (
          <SectionPlaceholder
            placeholder='"스타트업에 기대하는 점" 이 없어요'
            button='작성하러 가기 (+ 최대 3점)'
          />
        )}
      {(data.expectation.description ||
        data.expectation.keywords.length > 0) && (
        <Section>
          <SectionTitle>스타트업에 기대하는 점</SectionTitle>
          {data.expectation.keywords.length > 0 && (
            <TagContainer>
              {data.expectation.keywords.map((keyword) => (
                <Tag
                  key={keyword.id}
                  dangerouslySetInnerHTML={{ __html: keyword.name }}
                />
              ))}
            </TagContainer>
          )}
          {data.expectation.description && (
            <TextWrapper>
              <LineLimitedText text={data.expectation.description} />
            </TextWrapper>
          )}
          {shouldInduceToFill && data.expectation.description.length < 20 && (
            <ProfileFillButton>
              {`"스타트업에 기대하는 점" 보충하러 가기 (+ 2점)`}
            </ProfileFillButton>
          )}
        </Section>
      )}
      {shouldInduceToFill &&
        !data.jobSearchingReason &&
        data.workLevel.id === 3 && (
          <SectionPlaceholder
            placeholder='"이직하는 이유" 가 없어요'
            button='작성하러 가기 (+ 3점)'
          />
        )}
      {data.jobSearchingReason && data.workLevel.id === 3 && (
        <Section>
          <SectionTitle>이직하는 이유</SectionTitle>
          <TextWrapper>
            <LineLimitedText text={data.jobSearchingReason} />
          </TextWrapper>
          {shouldInduceToFill && data.jobSearchingReason.length < 20 && (
            <ProfileFillButton>
              {`"이직하는 이유" 보충하러 가기 (+ 3점)`}
            </ProfileFillButton>
          )}
        </Section>
      )}
      {!!data.careers?.length && (
        <Section>
          <SectionTitle>경력</SectionTitle>
          <ContentLayout>
            {data.careers.map((career, index) => (
              <div key={index}>
                <CareerDetail career={career} isNarrow={isNarrow} />
                {shouldInduceToFill && !career.description && (
                  <ProfileFillButton>{`"경력 > 상세 설명" 작성하러 가기 (+ 2점)`}</ProfileFillButton>
                )}
              </div>
            ))}
          </ContentLayout>
        </Section>
      )}
      {!!data.otherActivities?.length && (
        <Section>
          <SectionTitle>직무 관련 활동</SectionTitle>
          <ContentLayout>
            {data.otherActivities.map((item, index) => (
              <OtherActivityDetail
                key={index}
                data={item}
                isNarrow={isNarrow}
              />
            ))}
          </ContentLayout>
        </Section>
      )}
      {data.educations.length > 0 && (
        <Section>
          <SectionTitle>학력</SectionTitle>
          <Column style={{ gap: 24 }}>
            {data.educations.map((item) => (
              <DevEducation
                key={item.id}
                education={item}
                isNarrow={isNarrow}
              />
            ))}
          </Column>
        </Section>
      )}
      {shouldInduceToFill && !data.languageSkills.length && (
        <SectionPlaceholder
          placeholder='"외국어 능력" 이 없어요'
          button='작성하러 가기 (+ 1점 / 1개)'
        />
      )}
      {data.languageSkills.length > 0 && (
        <Section>
          <SectionTitle>외국어 능력</SectionTitle>
          <ContentLayout>
            {data.languageSkills.map((item) => (
              <div key={item.id}>
                <LanguageSkillDetail data={item} isNarrow={isNarrow} />
                {shouldInduceToFill && !item.description && (
                  <ProfileFillButton>{`"외국어 능력 > 상세 설명" 작성하러 가기`}</ProfileFillButton>
                )}
              </div>
            ))}
          </ContentLayout>
        </Section>
      )}
      {shouldInduceToFill && !hasAttachment && (
        <SectionPlaceholder
          placeholder='"첨부 자료" 가 없어요'
          button='업로드하러 가기 (+ 3점)'
        />
      )}
      {(isChatRooms || hasAttachment) && (
        <Section>
          <Row style={{ gap: 16 }}>
            <SectionTitle
              className={params?.hasAccessibleAttachment ? 'highlighted' : ''}
            >
              첨부 자료
            </SectionTitle>
            {!isChatBlocked &&
              isScoutChatRoom &&
              (!hasAttachment || !data?.isResumeAllowed) && (
                <BaseText
                  style={{ color: theme.color.secondary, cursor: 'pointer' }}
                  onClick={async () => {
                    if (!proposalId) return
                    analytics.track('click_dcr_resume_request_btn', {
                      requestId: data.id,
                      proposalId,
                    })
                    await postRequestDashboardScoutProposalRequestResume(
                      proposalId,
                    )
                  }}
                >
                  {!hasAttachment ? '업로드 요청하기' : '공개 요청하기'}
                </BaseText>
              )}
          </Row>
          <Attachments
            files={data.files}
            links={data.links}
            requestId={data.id}
            curationId={curationId}
            curatedAt={data.curatedAt}
          />
          <div style={{ paddingLeft: 40 }}>
            {hasAttachment && !data.isResumeAllowed ? (
              <InformText>
                {
                  '인재의 개인정보를 가린 파일만 볼 수 있어요.\n원본 파일과 링크는 스카우트 제안에 인재가 답장해올 때 공개돼요.'
                }
              </InformText>
            ) : data.links.length > 0 ? (
              <InformationBox isNarrow>
                링크에는 인재의 개인 정보가 포함되어 있을 수 있으며, 이를 통해
                직접 채용할 경우 위약금이 부과될 수 있습니다.
              </InformationBox>
            ) : (
              ''
            )}
          </div>
        </Section>
      )}
      {data.techStacks.length > 0 && (
        <Section>
          <SectionTitle>관심 스킬</SectionTitle>
          <TagContainer>
            {data.techStacks.map((item) => (
              <Tag
                key={item.id}
                dangerouslySetInnerHTML={{ __html: item.name }}
              />
            ))}
          </TagContainer>
        </Section>
      )}
      {!isApplicant && !isNarrow && (
        <Section>
          <SectionTitle>구직 조건</SectionTitle>
          <Column style={{ gap: 8 }}>
            <ConditionWrapper>
              <ProfileDetailSectionSubText>필수</ProfileDetailSectionSubText>
              {requiredConditions.length > 0 ? (
                <ConditionTags
                  data={requiredConditions}
                  params={params}
                  salary={data.salary}
                />
              ) : (
                <ProfileDetailSectionSubText>
                  필수 조건이 없어요.
                </ProfileDetailSectionSubText>
              )}
            </ConditionWrapper>
            <ConditionWrapper>
              <ProfileDetailSectionSubText>선호</ProfileDetailSectionSubText>
              {normalConditions.length > 0 ? (
                <ConditionTags
                  data={normalConditions}
                  params={params}
                  salary={data.salary}
                />
              ) : (
                <ProfileDetailSectionSubText>
                  선호 조건이 없어요.
                </ProfileDetailSectionSubText>
              )}
            </ConditionWrapper>
          </Column>
        </Section>
      )}
    </Container>
  )
}

const ConditionWrapper = styled(Row)`
  gap: 16px;
  align-items: flex-start;
`

const Container = styled(Column)`
  gap: 56px;
  position: relative;
  .highlighted {
    background-color: ${(p) => p.theme.color.highlight};
  }
`

const Section = styled(Column)`
  gap: 10px;
`

const SectionTitle = styled(BaseText)`
  color: #777a88;
`

const ContentLayout = styled(Column)`
  gap: 24px;
`

export const CoverLetterBox = styled(Column)`
  gap: 8px;
  padding: 16px;
  border-radius: 16px;
  background-color: ${(p) => p.theme.color.background};
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 16px;
  }
`

const BoxHeader = styled(SemiBoldText)``

const SummaryCard = styled(Card)`
  padding: 16px;
  background-color: ${(p) => p.theme.color.background};
`

const SummaryList = styled.ol`
  padding-inline-start: 24px;
  line-height: 1.8;
  font-size: ${(p) => p.theme.fontSize.default};
  li {
    font-weight: ${(p) => p.theme.fontWeight.semiBold};
    margin-top: 12px;
  }
`

const UserInfoCardWrapper = styled(Column)`
  gap: 32px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 24px;
  }
`

const ContactText = styled.a`
  text-decoration: underline;
  font-size: ${(p) => p.theme.fontSize.default};
`

const TextWrapper = styled(Row)`
  margin-left: 8px;
  padding-left: 16px;
  border-left: 2px solid ${(p) => p.theme.color.gray3};
`

const PlaceholderCard = styled(Card)`
  padding: 16px 24px;
  background-color: ${(p) => p.theme.color.background};
  gap: 8px;
  @media ${(p) => p.theme.deviceSize.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const PlaceholderText = styled(MediumText)`
  color: #7e7e7e;
`

const TextButton = styled(MediumText)`
  color: ${(p) => p.theme.color.primary};
  text-decoration: underline;
  @media ${(p) => p.theme.deviceSize.mobile} {
    text-align: center;
  }
`

const TextButtonWrapper = styled(Row)`
  justify-content: flex-end;
  @media ${(p) => p.theme.deviceSize.mobile} {
    justify-content: center;
  }
`

const InformText = styled(SmallText)`
  color: ${(p) => p.theme.color.primary7};
  line-height: 1.4;
`

const UnpublishedBanner = styled(Tag).attrs({ variant: TagType.RED })`
  span {
    white-space: nowrap;
  }
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  top: 67px;
  width: 100%;
  position: absolute;
`
