import styled from 'styled-components'
import { Card } from 'components/common/card'
import { Row } from 'components/common/layout'
import { BoldText } from 'components/common/text'

export const AssessmentCard = styled(Card)`
  flex: 1;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  justify-content: flex-start;
  border: 1px solid ${(p) => p.theme.color.line};
`

export const AssessmentScoreWrapper = styled(Row)`
  gap: 12px;
`

export const AssessmentScoreText = styled(BoldText)`
  color: ${(p) => p.theme.color.secondary};
  font-size: ${(p) => p.theme.fontSize.h5};
`
